import React, { useState, useEffect } from "react";
import { UserContext } from "./UserContext";
import { db, auth } from "../Firebase";
import User from "../types/User";

interface Props {
  children: any;
}

export default function UserProvider(props: Props) {
  const [state, setState] = useState({
    authenticated: false,
    name: "",
    uid: "",
    email: "",
    phone: "",
    role: "",
    roles: [] as Array<string>,
    group: "",
    groupName: "",
  });

  useEffect(() => {
    let user = auth.currentUser;
    if (user) {
      setState((prevState) => {
        return { ...prevState, authenticated: true };
      });
    } else {
      setState((prevState) => {
        return { ...prevState, authenticated: false };
      });
    }
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        db.collection("/users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            let userObj: User;
            if (!doc.exists) {
              userObj = {
                uid: user.uid,
                name: user.displayName ? user.displayName : user.email || "",
                email: user.email || "",
                phone: user.phoneNumber || "",
                group: "",
                role: "",
                roles: [] as Array<string>,
                groupName: "",
              };
              db.collection("/users").doc(user.uid).set(userObj);
            } else {
              userObj = doc.data() as User;
            }
            setState((prevState) => {
              return {
                ...prevState,
                authenticated: true,
                ...userObj,
              };
            });
          });
      } else {
        setState((prevState) => {
          return { ...prevState, authenticated: false };
        });
      }
    });
  }, []);

  function setAuthenticated(authenaticated: boolean) {
    setState((prevState) => ({ ...prevState, authenticated: authenaticated }));
  }

  function setUser(user: User) {
    setState((prevState) => ({ ...prevState, ...user }));
  }

  const userState = {
    ...state,
    setAuthenticated: setAuthenticated,
    setUser: setUser,
  };

  return (
    <UserContext.Provider value={userState}>
      {props.children}
    </UserContext.Provider>
  );
}
