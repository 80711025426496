import React from "react";
import { Container } from "@material-ui/core";

export default function AccessDeniedPage() {
  return (
    <Container maxWidth="md">
      <h2>Access Denied</h2>
      <p>You do not have permission to view this page.</p>
    </Container>
  );
}
