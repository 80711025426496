import React from "react";
import { Container, Paper } from "@material-ui/core";
import Users from "../components/Users";

export default function UsersPage() {
  return (
    <Container maxWidth="md">
      <Paper>
        <Users />
      </Paper>
    </Container>
  );
}
