import React from "react";

interface Props {
  message?: string;
}

export default function EmptyState(props: Props) {
  const { message } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 64,
        width: "100%",
      }}
    >
      {message ? message : "No data to display"}
    </div>
  );
}
