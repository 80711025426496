import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  Fragment,
} from "react";
import { UserContext } from "../context/UserContext";
import { db } from "../Firebase";
import Member from "../types/Member";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Button,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { KeyboardDatePicker } from "@material-ui/pickers";
import dayjs from "dayjs";
import Meeting from "../types/Meeting";
import Format from "../utils/Format";
import EmptyState from "./EmptyState";
import { TitleBar } from "./TitleBar";

export default function Attendance() {
  const userContext = useContext(UserContext);
  const [members, setMembers] = useState<Member[]>([]);
  const [checked, setChecked] = useState<string[]>([]);
  const [selectedDate, handleDateChange] = useState<Date | null>(new Date());
  const [lastUpdated, setLastUpdated] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const getAttendance = useCallback(() => {
    if (userContext.group && selectedDate && members.length > 0) {
      const date = dayjs(selectedDate).format(Format.DATE);
      db.collection(`groups/${userContext.group}/meetings`)
        .doc(date)
        .get()
        .then((doc) => {
          const meeting = doc.data() as Meeting;
          const attended = [];
          if (meeting && meeting.attendance) {
            const day = meeting.attendance;
            setLastUpdated(dayjs(meeting.lastUpdated).format(Format.TIMESTAMP));
            for (const m of day.members) {
              if (m.attended) {
                attended.push(m.id);
              }
            }
          } else {
            setLastUpdated("");
            for (const m of members) {
              attended.push(m.id);
            }
          }
          setChecked(attended);
        })
        .catch((error) => console.log(error));
    }
  }, [userContext.group, selectedDate, members]);

  const getMembers = useCallback(() => {
    if (userContext.group) {
      db.collection(`groups/${userContext.group}/members`)
        .orderBy("name")
        .get()
        .then((querySnapshot) => {
          const m: Member[] = [];
          querySnapshot.forEach((doc) => {
            let member = doc.data();
            member.id = doc.id;
            m.push(member as Member);
          });
          setMembers(m);
        });
    }
  }, [userContext.group]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    getAttendance();
  }, [getAttendance]);

  const handleCheck = (id: string) => {
    const isChecked = checked.includes(id);
    setChecked((prevState) => {
      if (!isChecked) {
        return [...prevState, id];
      } else {
        const index = prevState.findIndex((element) => element === id);
        if (index > -1) {
          prevState.splice(index, 1);
        }
        return [...prevState];
      }
    });
  };

  const handleSelectAll = () => {
    const isChecked = members.length === checked.length;
    if (!isChecked) {
      const list = [];
      for (const m of members) {
        list.push(m.id);
      }
      setChecked(list);
    } else {
      setChecked([]);
    }
  };

  const onSave = () => {
    if (selectedDate) {
      const attendanceList = [];
      const date = dayjs(selectedDate).format(Format.DATE);
      const total = members.length;
      const totalAttended = checked.length;
      for (const m of members) {
        m.attended = checked.includes(m.id);
        attendanceList.push(m);
      }
      const lastUpdatedDate = Date.now();
      const meeting: Meeting = {
        date: new Date(date),
        lastUpdated: lastUpdatedDate,
        attendance: {
          totalAttended,
          totalAbsent: total - totalAttended,
          percentAttended: (totalAttended / members.length) * 100,
          members: attendanceList,
        },
      };
      db.collection(`groups/${userContext.group}/meetings`)
        .doc(date)
        .set(meeting, { merge: true })
        .then(() => {
          setLastUpdated(dayjs(lastUpdatedDate).format(Format.TIMESTAMP));
          enqueueSnackbar("Attendance saved successfully");
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <TitleBar title="Attendance">
        <Button color="primary" onClick={onSave}>
          Save
        </Button>
      </TitleBar>

      {members.length > 0 ? (
        <>
          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            <KeyboardDatePicker
              autoOk
              value={selectedDate}
              onChange={(date) => handleDateChange(date?.toDate() ?? null)}
              variant="inline"
              inputVariant="outlined"
              format="MM/DD/YYYY"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              fullWidth
            />
          </div>
          <List dense={false} style={{ paddingTop: 16, paddingBottom: 0 }}>
            <ListItem button onClick={handleSelectAll} divider>
              <ListItemText style={{ fontSize: 12 }}>Select All</ListItemText>
              <ListItemSecondaryAction>
                <Checkbox
                  onChange={handleSelectAll}
                  checked={members.length === checked.length}
                  indeterminate={
                    checked.length > 0 && checked.length !== members.length
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
            {members.map((member) => (
              <ListItem
                key={member.id}
                button
                onClick={() => handleCheck(member.id)}
                divider
              >
                <ListItemText>{member.name}</ListItemText>
                <ListItemSecondaryAction>
                  <Checkbox
                    name={member.id}
                    onChange={() => handleCheck(member.id)}
                    checked={checked.includes(member.id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {lastUpdated && (
            <div
              style={{
                fontSize: 12,
                color: "gray",
                textAlign: "center",
                padding: 16,
              }}
            >
              Last Updated: {lastUpdated}
            </div>
          )}
        </>
      ) : (
        <EmptyState message="No members available. Please add a member first." />
      )}
    </>
  );
}
