import React from "react";
import "./App.css";
import { BrowserRouter, Route } from "react-router-dom";
import UserProvider from "./context/UserProvider";
import LoginPage from "./pages/LoginPage";
import { ThemeProvider, StylesProvider } from "@material-ui/core";
import AppTheme from "./AppTheme";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/dayjs";
import AppDrawer from "./components/AppDrawer";
import ResetPasswordPage from "./pages/ResetPasswordPage";

function App() {
  return (
    <ThemeProvider theme={AppTheme}>
      <StylesProvider injectFirst>
        <SnackbarProvider maxSnack={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ConfirmProvider>
              <BrowserRouter>
                <UserProvider>
                  <AppDrawer />
                  <Route exact path="/" component={LoginPage} />
                  <Route exact path="/login" component={LoginPage} />
                  <Route
                    exact
                    path="/reset-password"
                    component={ResetPasswordPage}
                  />
                </UserProvider>
              </BrowserRouter>
            </ConfirmProvider>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
