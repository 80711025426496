import React from "react";
import Member from "../types/Member";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, TextField } from "@material-ui/core";
import { db } from "../Firebase";
import { useFormik } from "formik";
import * as yup from "yup";

interface Props {
  group: string;
  open: boolean;
  member?: Member;
  onSuccess: () => void;
  onCancel: () => void;
}

export default function MemberInput(props: Props) {
  const { group, open, member, onSuccess, onCancel } = props;
  const formik = useFormik({
    initialValues: {
      name: member?.name ?? "",
    },
    enableReinitialize: true,
    onSubmit: saveMember,
    validationSchema: MemberInputSchema,
  });

  function saveMember(data: any) {
    if (member) {
      db.collection(`groups/${group}/members`)
        .doc(member.id)
        .update({
          name: data.name,
        })
        .then(() => {
          onSuccess();
          formik.resetForm();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      db.collection(`groups/${group}/members`)
        .add({
          name: data.name,
        })
        .then(() => {
          onSuccess();
          formik.resetForm();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <Dialog open={open}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="alert-dialog-slide-title">
          {member ? "Edit" : "Add"} Member
        </DialogTitle>
        <DialogContent style={{ minWidth: 300 }}>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const MemberInputSchema = yup.object({
  name: yup.string().required("Name is required"),
});
