import React from "react";
import HcSummaryPage from "../pages/HcSummaryPage";
import PrivateRoute from "../components/PrivateRoute";
import AccessDeniedPage from "../pages/AccessDeniedPage";
import AttendancePage from "../pages/AttendancePage";
import GroupsPage from "../pages/GroupsPage";
import HomePage from "../pages/HomePage";
import MembersPage from "../pages/MembersPage";
import NotesPage from "../pages/NotesPage";
import PrayerRequestsPage from "../pages/PrayerRequestsPage";
import ReportPage from "../pages/ReportPage";
import SummaryAdminPage from "../pages/SummaryAdminPage";
import SummaryPage from "../pages/SummaryPage";
import UsersPage from "../pages/UsersPage";
import VipsPage from "../pages/VipsPage";
import Roles from "../utils/Roles";
import SummaryArchiveAdminPage from "../pages/SummaryArchiveAdminPage";

export default function RoutesPrivate() {
  return (
    <>
      <PrivateRoute
        exact
        path="/home"
        component={HomePage}
        roles={[
          Roles.ADMIN,
          Roles.RECORDER,
          Roles.SHEPHERD,
          Roles.USER,
          Roles.SUPER,
        ]}
      />
      {/* <PrivateRoute
        exact
        path="/summary"
        component={SummaryPage}
        requiresLeader={true}
        roles={[Roles.ADMIN, Roles.SHEPHERD, Roles.USER, Roles.SUPER]}
      /> */}
      <PrivateRoute
        exact
        path="/review"
        component={SummaryPage}
        requiresLeader={true}
        roles={[Roles.ADMIN, Roles.SHEPHERD, Roles.USER, Roles.SUPER]}
      />
      <PrivateRoute
        exact
        path="/attendance"
        component={AttendancePage}
        requiresLeader={true}
        roles={[Roles.ADMIN, Roles.RECORDER, Roles.SHEPHERD, Roles.SUPER]}
      />
      <PrivateRoute
        exact
        path="/members"
        component={MembersPage}
        requiresLeader={true}
        roles={[Roles.ADMIN, Roles.SHEPHERD, Roles.SUPER]}
      />
      <PrivateRoute
        exact
        path="/notes"
        component={NotesPage}
        requiresLeader={true}
        roles={[Roles.ADMIN, Roles.SHEPHERD, Roles.SUPER]}
      />
      <PrivateRoute
        exact
        path="/vips"
        component={VipsPage}
        requiresLeader={true}
        roles={[Roles.ADMIN, Roles.SHEPHERD, Roles.SUPER]}
      />
      <PrivateRoute
        exact
        path="/prayer-requests"
        component={PrayerRequestsPage}
        requiresLeader={true}
        roles={[Roles.ADMIN, Roles.RECORDER, Roles.SHEPHERD, Roles.SUPER]}
      />
      <PrivateRoute
        exact
        path="/summary"
        component={HcSummaryPage}
        roles={[Roles.SHEPHERD]}
      />
      <PrivateRoute
        exact
        path="/groups"
        component={GroupsPage}
        roles={[Roles.ADMIN, Roles.SUPER]}
      />
      <PrivateRoute
        exact
        path="/reports"
        component={ReportPage}
        roles={[Roles.ADMIN, Roles.SUPER]}
      />
      <PrivateRoute
        exact
        path="/users"
        component={UsersPage}
        roles={[Roles.ADMIN, Roles.SUPER]}
      />
      <PrivateRoute
        exact
        path="/review-by-group"
        component={SummaryAdminPage}
        roles={[Roles.SUPER]}
      />
      <PrivateRoute
        exact
        path="/archived-groups"
        component={SummaryArchiveAdminPage}
        roles={[Roles.SUPER]}
      />
      <PrivateRoute exact path="/access-denied" component={AccessDeniedPage} />
    </>
  );
}
