import React from "react";
import Members from "../components/Members";
import { Container, Paper } from "@material-ui/core";

export default function MembersPage() {
  return (
    <Container maxWidth="md">
      <Paper>
        <Members />
      </Paper>
    </Container>
  );
}
