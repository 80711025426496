import React from "react";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";

interface Props {
  attended: number;
  absent: number;
}

export default function AttendancePie(props: Props) {
  const { attended, absent } = props;

  const total = attended + absent;

  const data = [
    {
      name: "Attended",
      value: attended,
      percent: 100,
    },
    {
      name: "Absent",
      value: absent,
      percent: 200,
    },
  ];

  const colors = ["#0073ce", "#EF5350", "#FFBB28", "#FF8042"];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          isAnimationActive={false}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={120}
          dataKey="value"
          label={(entry) => `${Math.round((entry.value / total) * 100)}%`}
        >
          {data.map((d, index) => (
            <Cell key={index} fill={colors[index % colors.length]} />
          ))}
        </Pie>

        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}
