import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { db } from "../Firebase";
import Group from "../types/Group";
import Summary from "./Summary";

interface SummaryAdminProps {
  showActive: boolean;
}

export default function SummaryAdmin({showActive}: SummaryAdminProps) {
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState<Group>();

  useEffect(() => {
    const getGroups = () => {
      db.collection(`groups`)
        .orderBy("name")
        .get()
        .then((querySnapshot) => {
          const g: Group[] = [];
          querySnapshot.forEach((doc) => {
            let group = doc.data();
            if(showActive === group.active) {
              group.id = doc.id;
              g.push(group as Group);
            }
          });
          setGroups(g);
          if (g.length > 0) {
            setSelectedGroup(g[0]);
          }
        });
    };

    getGroups();
  }, [showActive]);

  const handleChange = (event: any) => {
    setSelectedGroup(groups[event.target.value]);
    setSelectedIndex(event.target.value);
  };

  return (
    <>
      {groups.length > 0 && (
        <Grid item md={4} sm={12} xs={12}>
          <FormControl
            variant="outlined"
            style={{ marginBottom: 16, minWidth: 200 }}
            fullWidth
          >
            <InputLabel>Group to Review</InputLabel>
            <Select
              value={selectedIndex}
              onChange={handleChange}
              label="Group to Review"
            >
              {groups.map((group, index) => (
                <MenuItem value={index} key={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {selectedGroup && (
        <Paper style={{ padding: 16 }}>
          <Summary group={selectedGroup.id} isShepherd={false} />
        </Paper>
      )}
    </>
  );
}
