import React, { useState } from "react";
import { Paper, TextField, Button } from "@material-ui/core";
import { auth } from "../Firebase";
import { useHistory } from "react-router";
import * as yup from "yup";
import { useFormik } from "formik";

export default function ResetPassword() {
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [errors, setErrors] = useState("");
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: onSubmit,
    validationSchema: ResetPasswordSchema,
  });

  function onSubmit(data: any) {
    auth
      .sendPasswordResetEmail(data.email)
      .then(function () {
        setResetEmailSent(true);
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.message);
      });
  }

  return (
    <Paper style={{ padding: "25px 40px" }}>
      <h2>Reset Password</h2>
      {!resetEmailSent ? (
        <form onSubmit={formik.handleSubmit}>
          {errors.length > 0 ? (
            <div style={{ color: "#f44336", fontSize: 14 }}>{errors}</div>
          ) : null}

          <TextField
            value={formik.values.email}
            name="email"
            variant="outlined"
            label="Email"
            margin="normal"
            fullWidth
            autoComplete="email"
            onChange={formik.handleChange}
            error={formik.touched.email && formik.errors.email ? true : false}
            helperText={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
            autoFocus
          />

          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 25, marginBottom: 16 }}
            type="submit"
            fullWidth
          >
            Reset My Password
          </Button>
        </form>
      ) : (
        <>
          <p>
            The system has sent an email to allow you to reset your password.
            Please wait for the email to be delivered and follow the
            instructions contained inside.
          </p>

          <Button onClick={() => history.push("/login")}>Back to Login</Button>
        </>
      )}
    </Paper>
  );
}

const ResetPasswordSchema = yup.object({
  email: yup
    .string()
    .required("Email is required")
    .email("Valid email address required"),
});
