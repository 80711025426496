import {
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { TitleBar } from "./TitleBar";
import AddIcon from "@material-ui/icons/Add";
import { db } from "../Firebase";
import User from "../types/User";
import EmptyState from "./EmptyState";
import DeleteIcon from "@material-ui/icons/Delete";
import UserInput from "./UserInput";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { UserContext } from "../context/UserContext";

export default function Users() {
  const userContext = useContext(UserContext);
  const [users, setUsers] = useState<User[]>([]);
  const [editUser, setEditUser] = useState<User>();
  const [modal, setModal] = useState(false);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const getUsers = () => {
    db.collection(`users`)
      .orderBy("name")
      .get()
      .then((querySnapshot) => {
        const u: User[] = [];
        querySnapshot.forEach((doc) => {
          let user = doc.data();
          u.push(user as User);
        });
        setUsers(u);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (editUser) {
      setModal(true);
    }
  }, [editUser]);

  function onSaveSuccess() {
    setModal(false);
    getUsers();
  }

  function deleteUser(id: string, name: string) {
    confirm({
      description: `Are you sure you want to delete ${name}?`,
      title: `Delete`,
    }).then(() => {
      db.collection(`users`)
        .doc(id)
        .delete()
        .then(() => {
          enqueueSnackbar("User deleted successfully");
          getUsers();
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  return (
    <>
      <TitleBar title={`Users (${users.length})`} noMargin={true}>
        <IconButton color="primary" onClick={() => setModal(true)}>
          <AddIcon />
        </IconButton>
      </TitleBar>

      {users.length > 0 ? (
        <List dense={false} disablePadding>
          {users.map((user) => (
            <ListItem
              key={user.uid}
              button
              onClick={() => setEditUser(user)}
              divider
            >
              <ListItemText secondary={user.groupName}>
                {user.name}{" "}
                {user.roles?.map((role) =>
                  role ? (
                    <Chip
                      key={role}
                      label={role}
                      size="small"
                      style={{ marginRight: 5 }}
                    />
                  ) : null
                )}
              </ListItemText>
              {userContext.uid !== user.uid ? (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => deleteUser(user.uid, user.name)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          ))}
        </List>
      ) : (
        <EmptyState />
      )}

      <UserInput
        user={editUser}
        open={modal}
        onCancel={() => {
          setModal(false);
          setEditUser(undefined);
        }}
        onSuccess={onSaveSuccess}
      />
    </>
  );
}
