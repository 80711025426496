import React, { useContext, useState, useEffect, useCallback } from "react";
import { UserContext } from "../context/UserContext";
import { db } from "../Firebase";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import VipInput from "./VipInput";
import Vip from "../types/Vip";
import EmptyState from "./EmptyState";
import AddIcon from "@material-ui/icons/Add";
import { TitleBar } from "./TitleBar";

export default function Vips() {
  const userContext = useContext(UserContext);
  const [vips, setVips] = useState<Vip[]>([]);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [modal, setModal] = useState(false);
  const [editVip, setEditVip] = useState<Vip>();

  const getMembers = useCallback(() => {
    if (userContext.group) {
      db.collection(`groups/${userContext.group}/vips`)
        .orderBy("name")
        .get()
        .then((querySnapshot) => {
          const m: Vip[] = [];
          querySnapshot.forEach((doc) => {
            let member = doc.data();
            member.id = doc.id;
            m.push(member as Vip);
          });
          setVips(m);
        });
    }
  }, [userContext.group]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    if (editVip) {
      setModal(true);
    }
  }, [editVip]);

  function deleteUser(id: string, name: string) {
    confirm({
      description: `Are you sure you want to delete ${name}?`,
      title: `Delete`,
    }).then(() => {
      db.collection(`groups/${userContext.group}/vips`)
        .doc(id)
        .delete()
        .then(() => {
          enqueueSnackbar("VIP deleted successfully");
          getMembers();
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  function onAddMemberSuccess() {
    enqueueSnackbar("VIP added successfully!");
    setModal(false);
    getMembers();
  }

  return (
    <>
      <TitleBar title={`VIPs (${vips.length})`} noMargin={true}>
        <IconButton color="primary" onClick={() => setModal(true)}>
          <AddIcon />
        </IconButton>
      </TitleBar>

      {vips.length > 0 ? (
        <List dense={false} disablePadding>
          {vips.map((vip) => (
            <ListItem
              key={vip.id}
              button
              onClick={() => setEditVip(vip)}
              divider
            >
              <ListItemText>{vip.name}</ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => deleteUser(vip.id ?? "", vip.name ?? "")}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <EmptyState />
      )}
      <VipInput
        group={userContext.group}
        vip={editVip}
        open={modal}
        onSuccess={onAddMemberSuccess}
        onCancel={() => {
          setModal(false);
          setEditVip(undefined);
        }}
      />
    </>
  );
}
