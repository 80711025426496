import React, { useState, useEffect } from "react";
import { db } from "../Firebase";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Chip,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import Group from "../types/Group";
import GroupInput from "./GroupInput";
import AddIcon from "@material-ui/icons/Add";
import { TitleBar } from "./TitleBar";

export default function Groups() {
  const [groups, setGroups] = useState<Group[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [modal, setModal] = useState(false);
  const [editGroup, setEditGroup] = useState<Group>();

  const getGroups = () => {
    db.collection(`groups`)
      .orderBy("name")
      .get()
      .then((querySnapshot) => {
        const g: Group[] = [];
        querySnapshot.forEach((doc) => {
          let group = doc.data();
          group.id = doc.id;
          g.push(group as Group);
        });
        setGroups(g);
      });
  };

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (editGroup) {
      setModal(true);
    }
  }, [editGroup]);

  function onAddGroupSuccess() {
    enqueueSnackbar("Member added successfully!");
    setModal(false);
    getGroups();
  }

  function onEditGroup(group: Group) {
    setEditGroup(group);
  }

  return (
    <>
      <TitleBar title={`Groups (${groups.length})`} noMargin={true}>
        <IconButton color="primary" onClick={() => setModal(true)}>
          <AddIcon />
        </IconButton>
      </TitleBar>

      <List dense={false} disablePadding>
        {groups.map((group) => (
          <ListItem
            key={group.id}
            button
            onClick={() => {
              onEditGroup(group);
            }}
            divider
          >
            <ListItemText>
              {group.name}{" "}
              <Chip
                label={group.active ? "Active" : "Inactive"}
                size="small"
                color={group.active ? "primary" : "default"}
              />
            </ListItemText>
          </ListItem>
        ))}
      </List>

      <GroupInput
        group={editGroup}
        open={modal}
        onSuccess={onAddGroupSuccess}
        onCancel={() => {
          setModal(false);
          setEditGroup(undefined);
        }}
      />
    </>
  );
}
