import {
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
} from "@material-ui/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useState, useEffect, useCallback } from "react";
import { db } from "../Firebase";
import Meeting from "../types/Meeting";
import SummaryAttendance from "./SummaryAttendance";
import SummaryPrayerRequests from "./SummaryPrayerRequests";
import { NavigateNext, NavigateBefore } from "@material-ui/icons";
import EmptyState from "./EmptyState";
import Vip from "../types/Vip";
import Format from "../utils/Format";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";

interface Props {
  group: string;
  widget?: boolean;
  isShepherd: boolean;
}

export default function Summary(props: Props) {
  const { group, widget, isShepherd } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [meeting, setMeeting] = useState<Meeting>();
  const [vips, setVips] = useState<Vip[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  dayjs.extend(utc);

  const getMeetings = useCallback(() => {
    if (group) {
      db.collection(`groups/${group}/meetings`)
        .orderBy("date")
        .get()
        .then((querySnapshot) => {
          const m: Meeting[] = [];
          querySnapshot.forEach((doc) => {
            let meeting = doc.data();
            meeting.date = meeting.date.toDate();
            m.push(meeting as Meeting);
          });
          setMeetings(m);
          if (m.length > 0) {
            setSelectedIndex(m.length - 1);
            if (m.length > 0) {
              setMeeting(m[m.length - 1]);
            }
          }
        });
      db.collection(`groups/${group}/vips`)
        .orderBy("name")
        .get()
        .then((querySnapshot) => {
          const vips: Vip[] = [];
          querySnapshot.forEach((doc) => {
            let vip = doc.data() as Vip;
            vips.push(vip);
          });
          setVips(vips);
        });
    }
  }, [group]);

  useEffect(() => {
    getMeetings();
  }, [getMeetings]);

  const handleChange = (event: any) => {
    setMeeting(meetings[event.target.value]);
    setSelectedIndex(event.target.value);
  };

  const handleDateChange = (num: number) => {
    const newIndex = selectedIndex + num;
    setSelectedIndex(newIndex);
    setMeeting(meetings[newIndex]);
  };

  const onDelete = () => {
    if (meeting && group) {
      const displayDate = dayjs(meeting.date).utc().format(Format.DISPLAY);
      const date = dayjs(meeting.date).utc().format(Format.DATE);
      console.log(displayDate, date);
      confirm({
        description: `Are you sure you want to delete the meeting for ${displayDate}?`,
        title: `Delete`,
      }).then(() => {
        db.collection(`groups/${group}/meetings`)
          .doc(date)
          .delete()
          .then(() => {
            enqueueSnackbar("Meeting deleted successfully");
            getMeetings();
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  return (
    <>
      {group && meetings.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              style={{ marginRight: 20 }}
              disabled={selectedIndex <= 0}
              color="primary"
              onClick={() => handleDateChange(-1)}
            >
              <NavigateBefore />
            </IconButton>
            <FormControl variant="outlined">
              <Select value={selectedIndex} onChange={handleChange}>
                {meetings.map((m, index) => (
                  <MenuItem value={index} key={index}>
                    {dayjs(m.date).utc().format("MMM DD, YYYY")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton
              style={{ marginLeft: 20 }}
              disabled={selectedIndex >= meetings.length - 1}
              color="primary"
              onClick={() => handleDateChange(1)}
            >
              <NavigateNext />
            </IconButton>
          </div>
          <Grid container spacing={6}>
            <Grid item md={widget ? 12 : 7} sm={12} xs={12}>
              <h3>HC Summary</h3>
              <div style={{ marginBottom: 36 }}>
                {meeting?.summary ? (
                  <p style={{ whiteSpace: "pre-wrap" }}>{meeting?.summary}</p>
                ) : (
                  <p>No HC Summary to display</p>
                )}
              </div>

              <h3>Praise &amp; Prayer</h3>
              <SummaryPrayerRequests
                prayerRequests={meeting?.prayerRequests ?? []}
              />
            </Grid>
            <Grid item md={widget ? 12 : 5} sm={12} xs={12}>
              <SummaryAttendance attendance={meeting?.attendance} />

              {isShepherd && (
                <>
                  <h3>Notes</h3>
                  <div style={{ marginBottom: 26 }}>
                    {meeting?.notes ? (
                      <p style={{ whiteSpace: "pre-wrap" }}>{meeting?.notes}</p>
                    ) : (
                      <p>No notes to display</p>
                    )}
                  </div>
                </>
              )}

              <h3>Notes for the Pastor</h3>
              {meeting?.pastorNotes ? (
                <p style={{ whiteSpace: "pre-wrap" }}>{meeting?.pastorNotes}</p>
              ) : (
                <p>No Pastor notes to display</p>
              )}

              <h3>VIPs</h3>
              {vips.length > 0 ? (
                <>
                  {vips.map((vip, index) => (
                    <p key={`${index}-${vip.name}`}>{vip.name}</p>
                  ))}
                </>
              ) : (
                <p>No VIPs to display</p>
              )}
            </Grid>
          </Grid>
          {isShepherd && (
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button color="primary" onClick={onDelete}>
                Delete
              </Button>
            </div>
          )}
        </>
      ) : (
        <EmptyState message="No meetings to display." />
      )}
    </>
  );
}
