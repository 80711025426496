import React, { useEffect, useState } from "react";
import { db } from "../Firebase";
import { ReportAttendance as RA, ReportGroup } from "../types/ReportData";
import Meeting from "../types/Meeting";
import AttendancePie from "./AttendancePie";
import AttendanceBar from "./AttendanceBar";
import { IconButton, makeStyles, Paper } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import dayjs from "dayjs";
import ArrowIcon from "@material-ui/icons/PlayCircleFilled";
import AttendanceTable from "./AttendanceTable";

export default function ReportAttendance() {
  const [fromDate, setFromDate] = useState<Date | null>(
    dayjs().subtract(6, "month").toDate()
  );
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [report, setReport] = useState<RA>({
    groups: [],
    total: 0,
    totalAttended: 0,
    totalAbsent: 0,
  });

  function getAttendance() {
    setReport({
      groups: [],
      total: 0,
      totalAttended: 0,
      totalAbsent: 0,
    });
    db.collection("groups")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((item) => {
          db.collection(`groups/${item.id}/meetings`)
            .where("date", ">=", fromDate)
            .where("date", "<=", toDate)
            .get()
            .then((qs) => {
              const group: ReportGroup = {
                id: item.id,
                name: item.data().name,
                total: 0,
                totalAbsent: 0,
                totalAttended: 0,
              };
              qs.docs.forEach((item) => {
                const meeting = item.data() as Meeting;
                const attendance = meeting.attendance;
                if (attendance) {
                  group.total +=
                    attendance.totalAbsent + attendance.totalAttended;
                  group.totalAttended += attendance.totalAttended;
                  group.totalAbsent += attendance.totalAbsent;
                }
              });
              setReport((prevState) => {
                return {
                  total: (prevState.total += group.total),
                  totalAttended: (prevState.totalAttended +=
                    group.totalAttended),
                  totalAbsent: (prevState.totalAbsent += group.totalAbsent),
                  groups: [...prevState.groups, group],
                };
              });
            });
        });
      });
  }

  useEffect(() => {
    getAttendance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <>
      {report && (
        <>
          <Paper
            style={{
              padding: "11px 16px 16px 16px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className={classes.dateBar}>
              <KeyboardDatePicker
                autoOk
                label="From"
                value={fromDate}
                onChange={(date) => setFromDate(date?.toDate() ?? null)}
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                maxDate={toDate}
                fullWidth
                className={classes.dateItem}
                margin="normal"
              />
              <KeyboardDatePicker
                autoOk
                label="To"
                value={toDate}
                onChange={(date) => setToDate(date?.toDate() ?? null)}
                variant="inline"
                inputVariant="outlined"
                format="MM/DD/YYYY"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                maxDate={new Date()}
                minDate={fromDate}
                fullWidth
                className={classes.dateItem}
                margin="normal"
              />
              <IconButton color="primary" onClick={getAttendance}>
                <ArrowIcon />
              </IconButton>
            </div>

            <h2>Total Attendance</h2>
            <AttendancePie
              attended={report.totalAttended}
              absent={report.totalAbsent}
            />

            <h2>Attendance by Group (%)</h2>
            <AttendanceBar data={report} />
            <AttendanceTable report={report} />
          </Paper>
        </>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  dateBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  dateItem: {
    marginLeft: 8,
    marginRight: 8,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));
