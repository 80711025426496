import { List } from "@material-ui/core";
import React from "react";
import DayAttendance from "../types/DayAttendance";

interface Props {
  attendance?: DayAttendance;
}
export default function SummaryAttendance(props: Props) {
  const { attendance } = props;

  const attended =
    attendance?.members?.filter((member) => member.attended) ?? [];
  const absent =
    attendance?.members?.filter((member) => !member.attended) ?? [];

  return (
    <>
      <List>
        {attended.length > 0 && (
          <>
            <h3>Attended ({attended.length})</h3>
            {attended.map((member) => (
              <p key={member.id}>{member.name}</p>
            ))}
          </>
        )}

        {absent.length > 0 && (
          <>
            <h3>Absent ({absent.length})</h3>
            {absent.map((member) => (
              <p key={member.id}>{member.name}</p>
            ))}
          </>
        )}
      </List>
    </>
  );
}
