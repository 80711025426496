import React, { useContext } from "react";
import { Container, Paper } from "@material-ui/core";
import Summary from "../components/Summary";
import { UserContext } from "../context/UserContext";

export default function SummaryPage() {
  const userContext = useContext(UserContext);

  return (
    <Container maxWidth="lg">
      <Paper style={{ padding: 16 }}>
        <Summary group={userContext.group} isShepherd={true} />
      </Paper>
    </Container>
  );
}
