import React from "react";
import { Container, Paper } from "@material-ui/core";
import Groups from "../components/Groups";

export default function GroupsPage() {
  return (
    <Container maxWidth="md">
      <Paper>
        <Groups />
      </Paper>
    </Container>
  );
}
