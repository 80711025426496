import React from "react";
import { Container, Paper } from "@material-ui/core";
import PrayerRequests from "../components/PrayerRequests";

export default function PrayerRequestsPage() {
  return (
    <Container maxWidth="md">
      <Paper>
        <PrayerRequests />
      </Paper>
    </Container>
  );
}
