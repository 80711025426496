import { Divider } from "@material-ui/core";
import React, { FC } from "react";

interface Props {
  title: string;
  noMargin?: boolean;
}

export const TitleBar: FC<Props> = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 16,
          paddingRight: 4,
        }}
      >
        <div
          style={{
            flex: 1,
            fontWeight: 500,
            fontSize: 18,
            marginTop: 14,
            marginBottom: 14,
          }}
        >
          {props.title}
        </div>
        {props.children}
      </div>
      <Divider style={{ marginBottom: props.noMargin ? 0 : 16 }} />
    </>
  );
};
