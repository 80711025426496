import * as React from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";

interface PrivateRouteProps extends RouteProps {
  component?: any;
  roles?: Array<string>;
  componentProps?: any;
  requiresLeader?: boolean;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const user = React.useContext(UserContext);
  const { component: Component, roles, requiresLeader, ...rest } = props;
  let componentProps = {};
  if (props.componentProps) componentProps = props.componentProps;
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!user.authenticated) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={"/login?redirectUrl=" + location.pathname} />;
        }

        // not authorized
        if (roles && !roles.some((val) => user.roles.includes(val))) {
          return <Redirect to={"/access-denied"} />;
        }

        // not a leader
        if (requiresLeader && !user.group) {
          return <Redirect to={"/access-denied"} />;
        }

        // authorized so return component
        return <Component {...routeProps} {...componentProps} />;
      }}
    />
  );
};

export default PrivateRoute;
