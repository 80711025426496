import React, { useContext } from "react";
import { Container, Paper } from "@material-ui/core";
import { UserContext } from "../context/UserContext";

export default function HomePage() {
  const userContext = useContext(UserContext);

  return (
    <>
      <Container maxWidth="lg">
        <Paper style={{ height: "100%", padding: 16 }}>
          <h2>Hi, {userContext.name}!</h2>
          <p>
            I’m grateful for the gift of each of you who has committed to
            stewarding your God-given resources (your time, talent, treasure,
            and etc.) to serve God’s beloved church to fulfill Christ’s mission
            for his church which is to make disciples of Jesus who love God and
            love others as we are loved by God (Matthew 28:18-20).
          </p>
          <p>
            Let’s remember that church is not a place or an event, but God’s
            family marked by love and the Body of Christ built up by every
            member.
          </p>
          <p>
            To love like Jesus calls us to love, we must first abide in his love
            ourselves daily by remembering how we are loved by the Lord (1 Cor.
            13:4-7) so that we may love others like we are loved by Jesus (John
            13:34).{" "}
          </p>
          <p>
            As we partner together to fulfill Christ’s mission for His church, I
            commit to pouring my life into you in loving and caring for you as
            you do the same for your house church members.{" "}
          </p>
          <p>So, let’s go, Church!</p>
          <p>&nbsp;</p>
          <p>
            With great joy &amp; love of Christ,
            <br />
            Pastor KJ
          </p>
        </Paper>
      </Container>
    </>
  );
}
