import React from "react";
import { useHistory } from "react-router-dom";
import { Paper, Button, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import { auth } from "../Firebase";
import * as yup from "yup";

export default function Login() {
  const [errors, setErrors] = React.useState("");
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: onSubmit,
    validationSchema: LoginSchema,
  });

  function onSubmit(data: any) {
    auth
      .signInWithEmailAndPassword(data.email, data.password)
      .catch((error) => {
        setErrors("Invalid Email / Password");
      });
  }

  return (
    <Paper style={{ padding: "25px 40px" }}>
      <h2>Login</h2>
      <form onSubmit={formik.handleSubmit}>
        {errors.length > 0 ? (
          <div style={{ color: "#f44336", fontSize: 14 }}>{errors}</div>
        ) : null}

        <TextField
          value={formik.values.email}
          name="email"
          variant="outlined"
          label="Email"
          margin="normal"
          fullWidth
          autoComplete="email"
          onChange={formik.handleChange}
          error={formik.touched.email && formik.errors.email ? true : false}
          helperText={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : null
          }
        />
        <TextField
          value={formik.values.password}
          name="password"
          variant="outlined"
          label="Password"
          margin="normal"
          type="password"
          fullWidth
          onChange={formik.handleChange}
          error={
            formik.touched.password && formik.errors.password ? true : false
          }
          helperText={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : null
          }
        />

        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 25, marginBottom: 16 }}
          type="submit"
          fullWidth
        >
          Login
        </Button>
      </form>

      <Button
        onClick={() => {
          history.push("/reset-password");
        }}
        style={{ marginTop: 15 }}
      >
        Forgot your password?
      </Button>
    </Paper>
  );
}

const LoginSchema = yup.object({
  email: yup
    .string()
    .required("Email is required")
    .email("Valid email address required"),
  password: yup.string().required("Password is required"),
});
