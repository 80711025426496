import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

const AppTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0073ce",
      dark: "#004174",
    },
    secondary: {
      main: "#EF5350",
    },
  },
} as ThemeOptions);

export default AppTheme;
