import React from "react";
import PrayerRequest from "../types/PrayerRequest";

interface Props {
  prayerRequests: PrayerRequest[];
}
export default function SummaryPrayerRequests(props: Props) {
  const { prayerRequests } = props;
  const filtered = prayerRequests.filter(
    (pr) => pr.prayerRequest && pr.prayerRequest.length > 0
  );

  return (
    <>
      {filtered.length > 0 ? (
        <>
          {filtered.map((pr) => (
            <div
              style={{ paddingTop: 10, paddingBottom: 10 }}
              key={pr.memberId}
            >
              <div style={{ fontWeight: 600 }}>{pr.name}</div>
              {pr.prayerRequest}
            </div>
          ))}
        </>
      ) : (
        <>No praise &amp; prayer</>
      )}
    </>
  );
}
