import React, { useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { auth } from "../Firebase";
import RoutesPrivate from "../routes/RoutesPrivate";
import Roles from "../utils/Roles";

enum Routes {
  HOME = "Home",
  ATTENDANCE = "Attendance",
  PRAISE_PRAYER = "Praise & Prayer",
  NOTES = "Notes",
  VIPS = "VIPs",
  MEMBERS = "Members",
  REVIEW = "Review",
  USERS = "Users",
  GROUPS = "Groups",
  REPORTS = "Reports",
  LOGOUT = "Logout",
  HC_SUMMARY = "HC Summary",
  REVIEW_GROUPS = "Review Groups",
  REVIEW_ARCHIVED_GROUPS = "Review Archived Groups",
}

export default function AppDrawer() {
  const userContext = useContext(UserContext);
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useHistory();
  const [title, setTitle] = React.useState("Shepherd's Journal");
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!userContext.authenticated) {
    if (
      location.pathname !== "/reset-password" &&
      location.pathname !== "/login"
    ) {
      // not logged in so redirect to login page with the return url
      return <Redirect to={"/login?redirectUrl=" + location.pathname} />;
    } else {
      return null;
    }
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      {userContext.group && userContext.roles?.includes(Roles.SHEPHERD) ? (
        <>
          <Divider />
          <List>
            {[
              Routes.HOME,
              Routes.ATTENDANCE,
              Routes.PRAISE_PRAYER,
              Routes.NOTES,
              Routes.HC_SUMMARY,
              Routes.VIPS,
              Routes.MEMBERS,
              Routes.REVIEW,
            ].map((text) => (
              <ListItem button key={text} onClick={() => handleClick(text)}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </>
      ) : null}
      {userContext.group &&
      [Roles.RECORDER].some((val) => userContext.roles?.includes(val)) ? (
        <>
          <Divider />
          <List>
            {[Routes.HOME, Routes.ATTENDANCE, Routes.PRAISE_PRAYER].map(
              (text) => (
                <ListItem button key={text} onClick={() => handleClick(text)}>
                  <ListItemText primary={text} />
                </ListItem>
              )
            )}
          </List>
        </>
      ) : null}
      {[Roles.ADMIN, Roles.SUPER].some((val) =>
        userContext.roles.includes(val)
      ) ? (
        <>
          <Divider />
          <List>
            {[Routes.USERS, Routes.GROUPS, Routes.REPORTS].map((text) => (
              <ListItem button key={text} onClick={() => handleClick(text)}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
            {userContext.roles?.includes(Roles.SUPER) ? (
              <>
                {[Routes.REVIEW_GROUPS, Routes.REVIEW_ARCHIVED_GROUPS].map(
                  (text) => (
                    <ListItem
                      button
                      key={text}
                      onClick={() => handleClick(text)}
                    >
                      <ListItemText primary={text} />
                    </ListItem>
                  )
                )}
              </>
            ) : null}
          </List>
        </>
      ) : null}

      <Divider />
      <List>
        {[Routes.LOGOUT].map((text) => (
          <ListItem button key={text} onClick={() => handleClick(text)}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  function handleClick(text: string) {
    setMobileOpen(false);
    switch (text) {
      case Routes.MEMBERS:
        history.push("/members");
        break;
      case Routes.ATTENDANCE:
        history.push("/attendance");
        break;
      case Routes.PRAISE_PRAYER:
        history.push("/prayer-requests");
        break;
      case Routes.REPORTS:
        history.push("/reports");
        break;
      case Routes.GROUPS:
        history.push("/groups");
        break;
      case Routes.VIPS:
        history.push("/vips");
        break;
      case Routes.USERS:
        history.push("/users");
        break;
      case Routes.REVIEW:
        history.push("/review");
        break;
      case Routes.HOME:
        history.push("/home");
        break;
      case Routes.REVIEW_GROUPS:
        history.push("/review-by-group");
        break;
      case Routes.REVIEW_ARCHIVED_GROUPS:
        history.push("/archived-groups");
        break;
      case Routes.NOTES:
        history.push("/notes");
        break;
      case Routes.LOGOUT:
        auth.signOut();
        setTitle("Shepherd's Journal");
        break;
      case Routes.HC_SUMMARY:
        history.push("/summary");
        break;
    }
  }

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {title}
              {userContext.groupName ? <> - {userContext.groupName}</> : null}
            </Typography>
            <div style={{ flex: 1 }} />
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <RoutesPrivate />
        </main>
      </div>
    </>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));
