import React from "react";
import { Container } from "@material-ui/core";
import ReportAttendance from "../components/ReportAttendance";

export default function ReportPage() {
  return (
    <Container maxWidth="lg">
      <ReportAttendance />
    </Container>
  );
}
