import React, { Fragment } from "react";
import { Container, AppBar, Toolbar, Typography } from "@material-ui/core";
import ResetPassword from "../components/ResetPassword";
import { useHistory } from "react-router-dom";

export default function ResetPasswordPage() {
  const history = useHistory();
  return (
    <>
      <AppBar position="static" style={{ marginBottom: 25 }}>
        <Toolbar>
          <Typography
            variant="h6"
            style={{ flexGrow: 1, textAlign: "left", cursor: "pointer" }}
            onClick={() => history.push("/login")}
          >
            Shepherd's Journal
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xs">
        <ResetPassword />
      </Container>
    </>
  );
}
