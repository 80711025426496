import React from "react";
import { Container } from "@material-ui/core";
import SummaryAdmin from "../components/SummaryAdmin";

export default function SummaryArchiveAdminPage() {
  return (
    <Container maxWidth="lg">
      <SummaryAdmin showActive={false} />
    </Container>
  );
}
