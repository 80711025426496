import React from "react";
import { Container, Paper } from "@material-ui/core";
import Vips from "../components/Vips";

export default function VipsPage() {
  return (
    <Container maxWidth="md">
      <Paper>
        <Vips />
      </Paper>
    </Container>
  );
}
