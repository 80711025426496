import React from "react";
import { ReportAttendance } from "../types/ReportData";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

interface Props {
  report: ReportAttendance;
}
export default function AttendanceTable(props: Props) {
  const { report } = props;

  return (
    <Table>
      <TableHead>
        <TableRow
          style={{
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          <TableCell></TableCell>
          <TableCell align="right">Attended</TableCell>
          <TableCell align="right">Absent</TableCell>
          <TableCell align="right">%</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {report.groups.map((group) => (
          <TableRow key={group.id}>
            <TableCell>{group.name}</TableCell>
            <TableCell align="right">{group.totalAttended}</TableCell>
            <TableCell align="right">{group.totalAbsent}</TableCell>
            <TableCell align="right">{`${
              group.totalAttended > 0
                ? Math.round((group.totalAttended / group.total) * 100)
                : 0
            }%`}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>Overall</TableCell>
          <TableCell align="right">{report.totalAttended}</TableCell>
          <TableCell align="right">{report.totalAbsent}</TableCell>
          <TableCell align="right">{`${
            report.totalAttended > 0
              ? Math.round((report.totalAttended / report.total) * 100)
              : 0
          }%`}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
