import { createContext } from "react";
import User from "../types/User";

export const UserContext = createContext({
  authenticated: false,
  uid: "",
  name: "",
  email: "",
  phone: "",
  group: "",
  groupName: "",
  role: "",
  roles: [] as Array<string>,
  setAuthenticated: (authenticated: boolean) => {},
  setUser: (user: User) => {},
});
