import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, TextField } from "@material-ui/core";
import { db } from "../Firebase";
import { useFormik } from "formik";
import * as yup from "yup";
import Vip from "../types/Vip";

interface Props {
  group: string;
  open: boolean;
  vip?: Vip;
  onSuccess: () => void;
  onCancel: () => void;
}

export default function VipInput(props: Props) {
  const { group, open, vip, onSuccess, onCancel } = props;
  const formik = useFormik({
    initialValues: {
      name: vip?.name ?? "",
    },
    enableReinitialize: true,
    onSubmit: saveVip,
    validationSchema: MemberInputSchema,
  });

  function saveVip(data: any) {
    if (vip) {
      db.collection(`groups/${group}/vips`)
        .doc(vip.id)
        .update({
          name: data.name,
        })
        .then(() => {
          onSuccess();
          formik.resetForm();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      db.collection(`groups/${group}/vips`)
        .add({
          name: data.name,
        })
        .then(() => {
          onSuccess();
          formik.resetForm();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <Dialog open={open}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="alert-dialog-slide-title">
          {vip ? "Edit" : "Add"} VIP
        </DialogTitle>
        <DialogContent style={{ minWidth: 300 }}>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const MemberInputSchema = yup.object({
  name: yup.string().required("Name is required"),
});
