import React, { Fragment, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { Redirect, useLocation } from "react-router";
import queryString from "query-string";
import Login from "../components/Login";
import { Container, AppBar, Toolbar, Typography } from "@material-ui/core";

export default function LoginPage() {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const redirectUrl = queryString.parse(location.search).redirectUrl;

  return (
    <>
      {userContext.authenticated ? (
        <Redirect
          to={redirectUrl ? (redirectUrl as string) : "/home"}
        ></Redirect>
      ) : (
        <>
          <AppBar position="static" style={{ marginBottom: 25 }}>
            <Toolbar>
              <Typography
                variant="h6"
                style={{ flexGrow: 1, textAlign: "left", cursor: "pointer" }}
              >
                Shepherd's Journal
              </Typography>
            </Toolbar>
          </AppBar>
          <Container
            maxWidth="xs"
            style={{
              marginTop: 100,
            }}
          >
            <Login />
          </Container>
        </>
      )}
    </>
  );
}
