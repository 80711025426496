import React from "react";
import Attendance from "../components/Attendance";
import { Container, Paper } from "@material-ui/core";

export default function AttendancePage() {
  return (
    <Container maxWidth="md">
      <Paper>
        <Attendance />
      </Paper>
    </Container>
  );
}
