import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { db } from "../Firebase";
import { useFormik } from "formik";
import * as yup from "yup";
import Group from "../types/Group";

interface Props {
  group?: Group;
  open: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

export default function GroupInput(props: Props) {
  const { group, open, onSuccess, onCancel } = props;
  const formik = useFormik({
    initialValues: {
      name: group?.name ?? "",
      active: group?.active ?? true,
    },
    enableReinitialize: true,
    onSubmit: saveGroup,
    validationSchema: MemberInputSchema,
  });

  function saveGroup(data: any) {
    if (group) {
      db.collection(`groups`)
        .doc(group.id)
        .update({
          name: data.name,
          active: data.active,
        })
        .then(() => {
          onSuccess();
          formik.resetForm();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      db.collection(`groups`)
        .add({
          name: data.name,
          active: data.active,
        })
        .then(() => {
          onSuccess();
          formik.resetForm();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <Dialog open={open}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="alert-dialog-slide-title">
          {group ? "Edit" : "Add"} Group
        </DialogTitle>
        <DialogContent style={{ minWidth: 300 }}>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
            value={formik.values.name}
            onChange={formik.handleChange}
            style={{ marginBottom: 10 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.active}
                onChange={formik.handleChange}
                name="active"
                color="primary"
              />
            }
            label="Active"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>Cancel</Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const MemberInputSchema = yup.object({
  name: yup.string().required("Name is required"),
});
