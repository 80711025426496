import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ReportAttendance } from "../types/ReportData";

interface Props {
  data: ReportAttendance;
}

export default function AttendanceBar(props: Props) {
  const { data } = props;
  const [chartData, setChartData] = useState<
    {
      name: string;
      attended: number;
    }[]
  >([]);

  useEffect(() => {
    if (data.groups) {
      const d = [];
      for (const group of data.groups) {
        d.push({
          name: group.name,
          attended:
            group.total > 0
              ? Math.round((group.totalAttended / group.total) * 100)
              : 0,
        });
      }
      setChartData(d);
    }
  }, [data]);

  const colors = ["#0073ce", "#EF5350", "#FFBB28", "#FF8042"];

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart width={600} height={350} data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="attended" fill="#0073ce">
          {chartData.map((d, index) => (
            <Cell key={index} fill={colors[index % colors.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
