import React, { useContext, useState, useEffect, useCallback } from "react";
import { UserContext } from "../context/UserContext";
import { db } from "../Firebase";
import Member from "../types/Member";
import { Button, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { KeyboardDatePicker } from "@material-ui/pickers";
import dayjs from "dayjs";
import Meeting from "../types/Meeting";
import Format from "../utils/Format";
import PrayerRequest from "../types/PrayerRequest";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Formik } from "formik";
import EmptyState from "./EmptyState";
import { TitleBar } from "./TitleBar";
import { Accordion, AccordionSummary, AccordionDetails } from "./Accordion";

export default function PrayerRequests() {
  const userContext = useContext(UserContext);
  const [members, setMembers] = useState<Member[]>([]);
  const [requests, setRequests] = useState<PrayerRequest[]>([]);
  const [selectedDate, handleDateChange] = useState<Date | null>(new Date());
  const [lastUpdated, setLastUpdated] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState("");

  const getAttendance = useCallback(() => {
    if (userContext.group && selectedDate && members.length > 0) {
      const date = dayjs(selectedDate).format(Format.DATE);
      db.collection(`groups/${userContext.group}/meetings`)
        .doc(date)
        .get()
        .then((doc) => {
          const meeting = doc.data() as Meeting;
          const prayerRequests = [];
          if (meeting && meeting.prayerRequests) {
            const requests = meeting.prayerRequests;
            setLastUpdated(dayjs(meeting.lastUpdated).format(Format.TIMESTAMP));
            for (const m of members) {
              let match = false;
              for (const r of requests) {
                if (m.id === r.memberId) {
                  match = true;
                  prayerRequests.push({
                    memberId: m.id,
                    name: m.name,
                    prayerRequest: r.prayerRequest,
                  });
                }
              }
              if (!match) {
                prayerRequests.push({
                  memberId: m.id,
                  name: m.name,
                  prayerRequest: "",
                });
              }
            }
          } else {
            setLastUpdated("");
            for (const m of members) {
              prayerRequests.push({
                memberId: m.id,
                name: m.name,
                prayerRequest: "",
              });
            }
          }
          setRequests(prayerRequests);
        })
        .catch((error) => console.log(error));
    }
  }, [userContext.group, selectedDate, members]);

  const getMembers = useCallback(() => {
    if (userContext.group) {
      db.collection(`groups/${userContext.group}/members`)
        .orderBy("name")
        .get()
        .then((querySnapshot) => {
          const m: Member[] = [];
          querySnapshot.forEach((doc) => {
            let member = doc.data();
            member.id = doc.id;
            m.push(member as Member);
          });
          setMembers(m);
        });
    }
  }, [userContext.group]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    getAttendance();
  }, [getAttendance]);

  const onSave = (data: any) => {
    if (selectedDate) {
      const date = dayjs(selectedDate).format(Format.DATE);
      const lastUpdatedDate = Date.now();
      const meeting: Meeting = {
        date: new Date(date),
        lastUpdated: lastUpdatedDate,
        prayerRequests: data.requests,
      };
      db.collection(`groups/${userContext.group}/meetings`)
        .doc(date)
        .set(meeting, { merge: true })
        .then(() => {
          setLastUpdated(dayjs(lastUpdatedDate).format(Format.TIMESTAMP));
          enqueueSnackbar("Attendance saved successfully");
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      {members.length > 0 ? (
        <>
          <Formik
            initialValues={{
              requests: requests,
            }}
            enableReinitialize={true}
            onSubmit={onSave}
          >
            {({ values, handleSubmit, handleChange, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <TitleBar title="Praise &amp; Prayer">
                  <Button color="primary" type="submit">
                    Save
                  </Button>
                </TitleBar>

                <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                  <KeyboardDatePicker
                    autoOk
                    value={selectedDate}
                    onChange={(date) =>
                      handleDateChange(date?.toDate() ?? null)
                    }
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/DD/YYYY"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    fullWidth
                    style={{ marginBottom: 16 }}
                  />
                </div>
                {values.requests.map((request, index) => (
                  <Accordion
                    key={request.memberId}
                    expanded={expanded === request.memberId}
                    onChange={() =>
                      expanded === request.memberId
                        ? setExpanded("")
                        : setExpanded(request.memberId)
                    }
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {request.name} {!request.prayerRequest ? "*" : null}
                    </AccordionSummary>
                    <AccordionDetails>
                      <TextField
                        name={`requests[${index}].prayerRequest`}
                        value={request.prayerRequest}
                        label="Prayer Request"
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </form>
            )}
          </Formik>
          {lastUpdated && (
            <div
              style={{
                fontSize: 12,
                color: "gray",
                textAlign: "center",
                padding: 16,
              }}
            >
              Last Updated: {lastUpdated}
            </div>
          )}
        </>
      ) : (
        <EmptyState message="No members available. Please add a member first." />
      )}
    </>
  );
}
