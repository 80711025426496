import React, { useContext, useState, useEffect, useCallback } from "react";
import { UserContext } from "../context/UserContext";
import { db } from "../Firebase";
import { Button, TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { KeyboardDatePicker } from "@material-ui/pickers";
import dayjs from "dayjs";
import Meeting from "../types/Meeting";
import Format from "../utils/Format";
import { TitleBar } from "./TitleBar";

export default function HcSummary() {
  const userContext = useContext(UserContext);
  const [selectedDate, handleDateChange] = useState<Date | null>(new Date());
  const [lastUpdated, setLastUpdated] = useState("");
  const [summary, setSummary] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const getHcSummary = useCallback(() => {
    if (userContext.group && selectedDate) {
      const date = dayjs(selectedDate).format(Format.DATE);
      db.collection(`groups/${userContext.group}/meetings`)
        .doc(date)
        .get()
        .then((doc) => {
          const meeting = doc.data() as Meeting;
          if (meeting) {
            setLastUpdated(dayjs(meeting.lastUpdated).format(Format.TIMESTAMP));
            setSummary(meeting.summary ?? "");
          } else {
            setLastUpdated("");
            setSummary("");
          }
        })
        .catch((error) => console.log(error));
    }
  }, [userContext.group, selectedDate]);

  useEffect(() => {
    getHcSummary();
  }, [getHcSummary]);

  const onSave = () => {
    if (selectedDate) {
      const date = dayjs(selectedDate).format(Format.DATE);
      const lastUpdatedDate = Date.now();
      const meeting: Meeting = {
        date: new Date(date),
        lastUpdated: lastUpdatedDate,
        summary,
      };
      db.collection(`groups/${userContext.group}/meetings`)
        .doc(date)
        .set(meeting, { merge: true })
        .then(() => {
          setLastUpdated(dayjs(lastUpdatedDate).format(Format.TIMESTAMP));
          enqueueSnackbar("Summary saved successfully");
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <TitleBar title="HC Summary">
        <Button color="primary" onClick={onSave}>
          Save
        </Button>
      </TitleBar>

      <>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <KeyboardDatePicker
            autoOk
            value={selectedDate}
            onChange={(date) => handleDateChange(date?.toDate() ?? null)}
            variant="inline"
            inputVariant="outlined"
            format="MM/DD/YYYY"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            fullWidth
          />
        </div>
        <div style={{ padding: 16, paddingBottom: 16 }}>
          <TextField
            value={summary}
            multiline
            rows={10}
            onChange={(event) => setSummary(event.target.value)}
            variant="outlined"
            fullWidth
            label="Summary"
          />
        </div>
        {lastUpdated && (
          <div
            style={{
              fontSize: 12,
              color: "gray",
              textAlign: "center",
              padding: 16,
              paddingTop: 0,
            }}
          >
            Last Updated: {lastUpdated}
          </div>
        )}
      </>
    </>
  );
}
