import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { db } from "../Firebase";
import { useFormik } from "formik";
import * as yup from "yup";
import User from "../types/User";
import Group from "../types/Group";
import Roles from "../utils/Roles";

interface Props {
  open: boolean;
  user?: User;
  onSuccess: () => void;
  onCancel: () => void;
}

export default function UserInput(props: Props) {
  const { open, user, onSuccess, onCancel } = props;
  const [groups, setGroups] = useState<Group[]>([]);
  const formik = useFormik({
    initialValues: {
      uid: user?.uid ?? "",
      name: user?.name ?? "",
      group: user?.group ?? "none",
      groupName: user?.groupName ?? "",
      roles: user?.roles ?? [Roles.USER],
    },
    enableReinitialize: true,
    onSubmit: saveUser,
    validationSchema: UserInputSchema,
  });

  useEffect(() => {
    db.collection(`groups`)
      .orderBy("name")
      .get()
      .then((querySnapshot) => {
        const g: Group[] = [];
        querySnapshot.docs.forEach((doc) => {
          let gg = doc.data();
          gg.id = doc.id;
          g.push(gg as Group);
        });
        setGroups(g);
      });
  }, []);

  function saveUser(data: any) {
    const update = {
      uid: data.uid,
      name: data.name,
      group: data.group === "none" ? "" : data.group,
      groupName: data.groupName,
      roles: data.roles,
    };
    if (update.group) {
      for (const g of groups) {
        if (g.id === update.group) {
          update.groupName = g.name;
        }
      }
    }
    db.collection(`users`)
      .doc(data.uid)
      .set(update)
      .then(() => {
        onSuccess();
        formik.resetForm();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Dialog open={open}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="alert-dialog-slide-title">
          {user ? "Edit" : "Add"} User
        </DialogTitle>
        <DialogContent style={{ minWidth: 300 }}>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
            value={formik.values.name}
            onChange={formik.handleChange}
            margin="normal"
            error={formik.errors.name && formik.touched.name ? true : false}
            helperText={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
          />
          <TextField
            name="uid"
            label="UID"
            variant="outlined"
            fullWidth
            value={formik.values.uid}
            onChange={formik.handleChange}
            margin="normal"
            error={formik.errors.uid && formik.touched.uid ? true : false}
            helperText={
              formik.touched.uid && formik.errors.uid ? formik.errors.uid : null
            }
          />
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              name="roles"
              value={formik.values.roles}
              onChange={formik.handleChange}
              label="Role"
              multiple={true}
            >
              <MenuItem value={Roles.ADMIN}>Admin</MenuItem>
              <MenuItem value={Roles.RECORDER}>Recorder</MenuItem>
              <MenuItem value={Roles.SHEPHERD}>Shepherd</MenuItem>
              <MenuItem value={Roles.SUPER}>Super</MenuItem>
            </Select>
          </FormControl>
          {[Roles.SHEPHERD, Roles.RECORDER].some((val) =>
            formik.values.roles?.includes(val)
          ) ? (
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Group</InputLabel>
              <Select
                label="Group"
                value={formik.values.group}
                onChange={(event) => {
                  formik.setFieldValue("group", event.target.value);
                }}
              >
                <MenuItem value="none">None</MenuItem>
                {groups.map((g) => (
                  <MenuItem value={g.id} key={g.id}>
                    {g.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onCancel();
              formik.resetForm();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const UserInputSchema = yup.object({
  uid: yup.string().required("UID is required"),
  name: yup.string().required("Name is required"),
});
