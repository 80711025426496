import React, { useContext, useState, useEffect, useCallback } from "react";
import { UserContext } from "../context/UserContext";
import { db } from "../Firebase";
import Member from "../types/Member";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import MemberInput from "./MemberInput";
import EmptyState from "./EmptyState";
import AddIcon from "@material-ui/icons/Add";
import { TitleBar } from "./TitleBar";

export default function Members() {
  const userContext = useContext(UserContext);
  const [members, setMembers] = useState<Member[]>([]);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [modal, setModal] = useState(false);
  const [editMember, setEditMember] = useState<Member>();

  const getMembers = useCallback(() => {
    if (userContext.group) {
      db.collection(`groups/${userContext.group}/members`)
        .orderBy("name")
        .get()
        .then((querySnapshot) => {
          const m: Member[] = [];
          querySnapshot.forEach((doc) => {
            let member = doc.data();
            member.id = doc.id;
            m.push(member as Member);
          });
          setMembers(m);
        });
    }
  }, [userContext.group]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    if (editMember) {
      setModal(true);
    }
  }, [editMember]);

  function deleteUser(id: string, name: string) {
    confirm({
      description: `Are you sure you want to delete ${name}?`,
      title: `Delete`,
    }).then(() => {
      db.collection(`groups/${userContext.group}/members`)
        .doc(id)
        .delete()
        .then(() => {
          enqueueSnackbar("Member deleted successfully");
          getMembers();
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  function onAddMemberSuccess() {
    enqueueSnackbar("Member added successfully!");
    setModal(false);
    getMembers();
  }

  return (
    <>
      <TitleBar title={`Members (${members.length})`} noMargin={true}>
        <IconButton color="primary" onClick={() => setModal(true)}>
          <AddIcon />
        </IconButton>
      </TitleBar>

      {members.length > 0 ? (
        <List dense={false} disablePadding>
          {members.map((member) => (
            <ListItem
              key={member.id}
              button
              onClick={() => setEditMember(member)}
              divider
            >
              <ListItemText>{member.name}</ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => deleteUser(member.id ?? "", member.name ?? "")}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <EmptyState />
      )}

      <MemberInput
        group={userContext.group}
        member={editMember}
        open={modal}
        onSuccess={onAddMemberSuccess}
        onCancel={() => {
          setModal(false);
          setEditMember(undefined);
        }}
      />
    </>
  );
}
